import axios from '@/axios'

const route = "budget/forming_price_data";

export default {
    getAll(formingPriceId) {
        return axios.get(route+'/'+formingPriceId);
    },

    storeOrUpdate(formingPriceData) {
        return axios.post(route + '/store_or_update', formingPriceData)
    },

    getAllCost(){
        return axios.get(route + '/get_all_cost')
    },

    store(formingPriceData) {
        return axios.post(route + '/new_forming_price', formingPriceData)
    },

    update(formingPriceData,formingPriceId){
      return axios.put(route + '/update_forming_price/'+formingPriceId, formingPriceData)  
    }



}
