
<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Formação de Preço</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <!-- <v-spacer></v-spacer> -->
         <v-text-field
          v-model="nameFormingPrice"
          label="Nome da Formação de Preço"
          class="mt-5"
          @change="save"
        ></v-text-field>      
        <!-- <v-btn color="primary" :loading="btnSaveLoading" rounded dark class="ml-6 " @click="saveData">Salvar</v-btn> -->
            
      </v-toolbar>
      <!-- Custos -->
      <v-card>
        <div class="title_table">
          <div style="float: left">1 - CUSTOS</div>
          <div style="float: right">R$ {{ formatMoney(costTotal) }}</div>
        </div>
        <v-data-table
          :loading="loadingTables"
          :calculate-widths="true"
          dense
          hide-default-footer
          :headers="headers"
          :items="costs"
          elevation="2"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="removeCost(item)"> mdi-delete </v-icon>
          </template>

          <template v-slot:item.name="props">
            {{ props.item.cost.name }}
          </template>

          <template v-slot:item.value="props">
            <div>{{ formatMoney(props.item.value) }}</div>
          </template>

          <template v-slot:no-data> Nenhum registro cadastrado </template>
        </v-data-table>
        <v-btn
          class="mx-2 mb-1"
          fab
          dark
          small
          color="primary"
          @click="getAllCost"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-card>
      <!-- tela pra adicionar novo custo -->
      <v-dialog v-model="dialogAddCost" max-width="700" height="700">
        <v-card>
          <v-card-title class="text-h5"> Adicionar Custo </v-card-title>
          <v-card-text>
            <v-data-table
              :loading="loadingTableAddCost"
              v-model="selectedCost"
              :headers="headersAddCost"
              :items="itemsAddCosts"
              item-key="name"
              show-select
              class="elevation-1"
              :footer-props="{
                itemsPerPageText: 'Itens por pagina',
                itemsPerPageOptions: [5, 10, 15],
                showFirstLastPage: false,
              }"
            >
              <template v-slot:no-data> Nenhum registro disponível </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  text @click="dialogAddCost = false">
              Fechar
            </v-btn>
            <v-btn color="primary" @click="applyAddCost"> Aplicar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- fim costs -->

      <!-- despesas indiretas -->
      <v-card>
        <div class="title_table mt-6">
          <div style="float: left">2 - DESPESAS INDIRETAS</div>
          <div style="float: right">R$ {{ formatMoney(overheadTotal) }}</div>
        </div>
        <v-data-table
          :loading="loadingTables"
          :calculate-widths="true"
          dense
          hide-default-footer
          :headers="headers_overhead"
          :items="overhead"
          elevation="2"
        >
          <template v-slot:item.cost_total="props">
            <div v-if="props.item.edited_base == 'yes'">
              <v-edit-dialog
                :return-value.sync="props.item.cost_total"
                persistent
                large
                cancel-text="CANCELAR"
                save-text="SALVAR"
                @save="edited_base(props.item)"
              >
                <div class="editable">
                  {{ formatMoney(props.item.cost_total) }}
                </div>
                <template v-slot:input>
                  <vuetify-money
                    v-model="props.item.cost_total"
                    :clearable="true"
                    valueWhenIsEmpty="0"
                    :options="{
                      locale: 'pt-BR',
                      prefix: 'R$',
                      length: 11,
                      precision: 2,
                    }"
                  />
                </template>
              </v-edit-dialog>
            </div>
            <div v-else>
              <div>
                {{ formatMoney(props.item.cost_total) }}
              </div>
            </div>
          </template>

          <template v-slot:item.name="props">
            <v-edit-dialog
              :return-value.sync="props.item.name"
              persistent
              large
              cancel-text="CANCELAR"
              save-text="SALVAR"
              @save="save"
            >
              <div class="editable">{{ props.item.name }}</div>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.name"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.value="props">
            <div>{{ formatMoney(props.item.value) }}</div>
          </template>

          <template v-slot:item.percentage="props">
            <v-edit-dialog
              :return-value.sync="props.item.percentage"
              persistent
              large
              cancel-text="CANCELAR"
              save-text="SALVAR"
              @save="save"
            >
              <div class="editable">
                {{ formatMoney(props.item.percentage) }}
              </div>
              <template v-slot:input>
                <vuetify-money
                  v-model="props.item.percentage"
                  :clearable="true"
                  valueWhenIsEmpty="0"
                  :options="{
                    locale: 'pt-BR',
                    suffix: '%',
                    length: 11,
                    precision: 2,
                  }"
                />
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small @click="removeOverhead(item)"> mdi-delete </v-icon>
            <v-icon
              v-if="item.edited_base == 'yes'"
              small
              @click="padLock(item)"
            >
              mdi-lock-open-alert
            </v-icon>
            <v-icon v-else small @click="padLock(item)"> mdi-lock </v-icon>
          </template>

          <template v-slot:no-data> Nenhum registro cadastrado </template>
        </v-data-table>
        <v-btn
          class="mx-2 mb-1"
          fab
          dark
          small
          color="primary"
          @click="add_overhead()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-card>
      <!-- Lucro -->
      <v-card>
      <div class="title_table mt-6">
        <div style="float: left">3 - LUCRO</div>
        <div style="float: right">R$ {{ formatMoney(profitTotal) }}</div>
      </div>
      <v-data-table
        :loading="loadingTables"
        dense
        hide-default-footer
        :headers="headers_profit"
        :items="profit"
        elevation="2"
      >
        <template v-slot:item.cost_total="props">
          <div v-if="props.item.edited_base == 'yes'">
            <v-edit-dialog
              :return-value.sync="props.item.cost_total"
              persistent
              large
              cancel-text="CANCELAR"
              save-text="SALVAR"
              @save="save"
            >
              <div class="editable">
                {{ formatMoney(props.item.cost_total) }}
              </div>
              <template v-slot:input>
                <vuetify-money
                  v-model="props.item.cost_total"
                  :clearable="true"
                  valueWhenIsEmpty="0"
                  :options="{
                    locale: 'pt-BR',
                    prefix: 'R$',
                    length: 11,
                    precision: 2,
                  }"
                />
              </template>
            </v-edit-dialog>
          </div>
          <div v-else>
            {{ formatMoney(props.item.cost_total) }}
          </div>
        </template>

        <template v-slot:item.name="props">
          <v-edit-dialog
            :return-value.sync="props.item.name"
            persistent
            large
            cancel-text="CANCELAR"
            save-text="SALVAR"
            @save="save"
          >
            <div class="editable">{{ props.item.name }}</div>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.name"
                label="Edit"
                single-line
                counter
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.value="props">
          <div>{{ formatMoney(props.item.value) }}</div>
        </template>

        <template v-slot:item.percentage="props">
          <v-edit-dialog
            :return-value.sync="props.item.percentage"
            persistent
            large
            cancel-text="CANCELAR"
            save-text="SALVAR"
            @save="save"
          >
            <div class="editable">{{ formatMoney(props.item.percentage) }}</div>
            <template v-slot:input>
              <vuetify-money
                v-model="props.item.percentage"
                :clearable="true"
                valueWhenIsEmpty="0"
                :options="{
                  locale: 'pt-BR',
                  suffix: '%',
                  length: 11,
                  precision: 2,
                }"
              />
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="removeProfit(item)"> mdi-delete </v-icon>
          <v-icon v-if="item.edited_base == 'yes'" small @click="padLock(item)">
            mdi-lock-open-alert
          </v-icon>
          <v-icon v-else small @click="padLock(item)"> mdi-lock </v-icon>
        </template>
        <template v-slot:no-data> Nenhum registro cadastrado </template>
      </v-data-table>
      <!--taxes-->
      <v-btn class="mx-2 mb-1" fab dark small color="primary" @click="add_profit()">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      </v-card>

      <!-- IMPOSTOS  -->
      <v-card>
      <div class="title_table mt-6">
        <div style="float: left">4 - IMPOSTOS</div>
        <div style="float: right">R$ {{ formatMoney(taxesTotal) }}</div>
      </div>

      <v-data-table
        :loading="loadingTables"
        :calculate-widths="true"
        dense
        hide-default-footer
        :headers="headers_profit"
        :items="taxes"
        elevation="2"
      >
        <template v-slot:item.name="props">
          <v-edit-dialog
            :return-value.sync="props.item.name"
            persistent
            large
            cancel-text="CANCELAR"
            save-text="SALVAR"
            @save="save"
          >
            <div class="editable">{{ props.item.name }}</div>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.name"
                label="Edit"
                single-line
                counter
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.cost_total="props">
          <div v-if="props.item.edited_base == 'yes'">
            <v-edit-dialog
              :return-value.sync="props.item.cost_total"
              persistent
              large
              cancel-text="CANCELAR"
              save-text="SALVAR"
              @save="edited_base(props.item)"
            >
              <div class="editable">
                {{ formatMoney(props.item.cost_total) }}
              </div>
              <template v-slot:input>
                <vuetify-money
                  v-model="props.item.cost_total"
                  :clearable="true"
                  valueWhenIsEmpty="0"
                  :options="{
                    locale: 'pt-BR',
                    prefix: 'R$',
                    length: 11,
                    precision: 2,
                  }"
                />
              </template>
            </v-edit-dialog>
          </div>
          <div v-else>
            {{ formatMoney(props.item.cost_total) }}
          </div>
        </template>

        <template v-slot:item.value="props">
          <div>{{ formatMoney(props.item.value) }}</div>
        </template>

        <template v-slot:item.percentage="props">
          <v-edit-dialog
            :return-value.sync="props.item.percentage"
            persistent
            large
            cancel-text="CANCELAR"
            save-text="SALVAR"
            @save="save"
          >
            <div class="editable">{{ formatMoney(props.item.percentage) }}</div>
            <template v-slot:input>
              <vuetify-money
                v-model="props.item.percentage"
                :clearable="true"
                valueWhenIsEmpty="0"
                :options="{
                  locale: 'pt-BR',
                  suffix: '%',
                  length: 11,
                  precision: 2,
                }"
              />
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small @click="removeTaxes(item)"> mdi-delete </v-icon>
          <v-icon v-if="item.edited_base == 'yes'" small @click="padLock(item)">
            mdi-lock-open-alert
          </v-icon>
          <v-icon v-else small @click="padLock(item)"> mdi-lock </v-icon>
        </template>

        <template v-slot:no-data> Nenhum registro cadastrado </template>
      </v-data-table>
      <v-btn class="mx-2 mb-1" fab dark small color="primary" @click="add_taxes()">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      </v-card>
      <!-- fim taxes -->

      <div class="title_table mt-6">
        <div style="float: left">PREÇO FINAL</div>
        <div style="float: right">R$ {{ formatMoney(priceTotal) }}</div>
      </div>

      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters } from 'vuex'  
import service from "./service";
export default {
  data() {
    return {
      btnSaveLoading:false,
      FormingPriceId:null,
      nameFormingPrice:'Nova Formação de Preço',
      loadingTables: false,
      priceTotal: 0,
      taxesTotal: 0,
      overheadTotal: 0,
      costTotal: 0,
      profitTotal: 0,
      snack: false,
      snackColor: "",
      snackText: "",
      selectedCost: [],
      dialogAddCost: false,
      itemsAddCosts: [],
      loadingTableAddCost: false,
      max25chars: (v) => v.length <= 40 || "Maximo 40 caracteres",
      pagination: {},
      headersAddCost: [
        { text: "Nome", align: "start", value: "name", sortable: false },
      ],
      headers: [
        {
          text: "",
          width: 3,
          align: "start",
          value: "actions",
          sortable: false,
        },
        { text: "", width: 3, align: "start", value: "item", sortable: false },
        { text: "", sortable: false, value: "name" },
        { text: "R$", width: 4, align: "end", value: "value", sortable: false },
      ],

      headers_overhead: [
        {
          text: "AÇÕES",
          align: "start",
          value: "actions",
          sortable: false,
          width: true,
        },

        {
          text: "",
          width: true,
          align: "start",
          value: "item",
          sortable: false,
        },
        { text: "", align: "start", sortable: false, value: "name" },
        { text: "R$", align: "end", value: "cost_total", sortable: false },
        {
          text: "%",
          width: 3,
          align: "end",
          value: "percentage",
          sortable: false,
        },
        { text: "R$", width: 4, align: "end", value: "value", sortable: false },
      ],

      headers_profit: [
        {
          text: "AÇÕES",
          width: 3,
          align: "start",
          value: "actions",
          sortable: false,
        },
        { text: "", width: 3, align: "start", value: "item", sortable: false },
        { text: "", align: "start", sortable: false, value: "name" },
        { text: "R$", align: "end", value: "cost_total", sortable: false },
        {
          text: "%",
          width: 3,
          align: "end",
          value: "percentage",
          sortable: false,
        },
        { text: "R$", width: 3, align: "end", value: "value", sortable: false },
      ],
      costs: [],
      costsDefault: [],
      overhead: [],
      overheadDefault: [
        {
          item: "2.1",
          name: "ADMINISTRAÇÃO CENTRAL",
          cost_total: 0,
          percentage: 0,
          value: 0,
          edited_base: "not",
        },
        {
          item: "2.2",
          name: "SEGURO",
          cost_total: "0",
          percentage: "0",
          value: "0",
          edited_base: "not",
        },
        {
          item: "2.3",
          name: "RISCO",
          cost_total: "0",
          percentage: "0",
          value: "0",
          edited_base: "not",
        },
        {
          item: "2.4",
          name: "GARANTIA",
          cost_total: "0",
          percentage: "0",
          value: "0",
          edited_base: "not",
        },
        {
          item: "2.5",
          name: "DESPESAS FINANCEIRAS",
          cost_total: "0",
          percentage: "0",
          value: "0",
          edited_base: "not",
        },
      ],
      profit: [],
      profitDefault: [
        {
          item: "3.1",
          name: "LUCRO",
          cost_total: "0",
          percentage: "0",
          value: "0",
          edited_base: "not",
        },
      ],
      taxes: [],
      taxesDefault: [
        {
          item: "4.1",
          name: "ISS",
          cost_total: 0,
          percentage: 0,
          value: 0,
          edited_base: "not",
        },
        {
          item: "4.2",
          name: "PIS",
          cost_total: 0,
          percentage: 0,
          value: 0,
          edited_base: "not",
        },
        {
          item: "4.3",
          name: "CONFINS",
          cost_total: 0,
          percentage: 0,
          value: 0,
          edited_base: "not",
        },
        {
          item: "4.4",
          name: "CONTRIBUIÇÃO SOCIAL (CSLL)",
          cost_total: 0,
          percentage: 0,
          value: 0,
          edited_base: "not",
        },
        {
          item: "4.5",
          name: "IRPJ",
          cost_total: 0,
          percentage: 0,
          value: 0,
          edited_base: "not",
        },
        {
          item: "4.6",
          name: "CONTRIBUIÇÃO PREVIDÊNCIARIA (CPRB)",
          cost_total: 0,
          percentage: 0,
          value: 0,
          edited_base: "not",
        },
      ],
    };
  },

  created() {
    this.FormingPrice =  this.getFormingPrice;
    
    if(Object.keys(this.FormingPrice).length != 0){
      this.nameFormingPrice = this.FormingPrice.name
      this.loadingTables = true;
      service
        .getAll(this.FormingPrice.id)
        .then((response) => {
          
          this.loadingTables = false;
          let data = response.data;
          this.costs = data.filter((value) => value.group == "costs");
          //despesas indiretas
          this.overhead = data.filter((value) => value.group == "overhead");
          //lucro
          this.profit = data.filter((value) => value.group == "profit");
          //impostos
          this.taxes = data.filter((value) => value.group == "taxes");
        
          
        })
        .catch(() => {
          
          this.$toast.error("Não foi possível carregar os dados da tabela.");
        }).finally(()=>{
          this.loadingTables = false;
          this.calcValueCost(this.costs); //calcula o valor total de cada custo
          this.save();
        })  
    }else{

       this.overhead = this.overheadDefault
       this.profit = this.profitDefault
       this.taxes = this.taxesDefault
       this.costs = []
       this.save();
    }

    
  },
  computed:{
    ...mapGetters('FormingPrice',['getFormingPrice'])
  },
  methods: {
    calcValueCost(cost) {
      //itera sobra cada custo
      let totalCost = 0
      cost.forEach((item) => {
        
        if (item.cost.composition != null){
            let compositions = item.cost.composition 
            //soma o valor de todas as composicoes associado a um custo
            totalCost = compositions.reduce((preValue, curValue) => {
                  //calcula o valor da composicao veses a quantidade definida no orçamento
                  return (
                    preValue +
                    this.parseNumber(curValue.total_with_tax) *
                      this.parseNumber(curValue.pivot.quantity)
                  );
            },0);
            
        }else{
           totalCost = 0
        }
        //seta o valor do custo
        item.value = totalCost;
        
      });
    },
    applyAddCost() {
      this.selectedCost.forEach((item) => {
        this.add_cost(item);
      });
      this.selectedCost = [];
      this.dialogAddCost = false;
    },
    getAllCost() {
      this.loadingTableAddCost = true;
      this.itemsAddCosts = [];
      service
        .getAllCost()
        .then((result) => {
          // faz um filtro dos custos q existem na formacao de preço com os custos carregado na base.
          //traz somente os q nao consta na formacao de preço 
          this.itemsAddCosts = result.data.filter((item1) => {
             let contain = false
             this.costs.forEach((item2)=>{
                         
                if (item1.id == item2.cost_id){
                  contain = true
                }
            },contain)
          
          if(contain == false){
            return true
          }



          });
        })
        .catch(() => {
          
        }).finally(()=>{
          this.loadingTableAddCost = false;
        })
      this.dialogAddCost = true;
    },
    edited_base(item) {
      item.edited_base = "yes";
      this.save();
    },

    save() {
      setTimeout(() => {
        this.calculator();
        this.saveData()
      }, 50);
    },

    calculator() {
      let costTotal = this.calcCost();
      this.costTotal = costTotal;

      let overheadTotal = this.calcOverhead(costTotal);
      this.overheadTotal = overheadTotal;

      let profitTotal = this.calcProfit(overheadTotal, costTotal);
      this.profitTotal = profitTotal;

      let taxesTotal = this.calcTaxes(costTotal, overheadTotal, profitTotal);
      this.taxesTotal = taxesTotal;

      //soma todos os totais

      this.priceTotal =
        parseFloat(taxesTotal) +
        parseFloat(overheadTotal) +
        parseFloat(costTotal) +
        parseFloat(profitTotal);
    },
    calcCost() {
      //custos
      this.costs.forEach((item) => {
        item.value = parseFloat(item.value);
      });

      let cost_total = this.costs.reduce(
        (acc, cur) => acc + this.parseNumber(cur.value),
        0
      );

      return cost_total;
    },
    calcOverhead(cost_total) {
      // despesas indiretas
      //seta o valor base para todas as despesas indiretas
      this.overhead.forEach((item) => {
        if (item.edited_base == "not") {
          item.cost_total = cost_total;
        }
      });

      //calcula o valor de cada dispesa indireta e seta o valor
      this.overhead.forEach((item) => {
        if (item.edited_base == "not") {
          item.value = this.calcPercentage(item.percentage, cost_total);
        } else {
          item.value = this.calcPercentage(item.percentage, item.cost_total);
        }
      });

      //total despesas indiretas
      let overhead_total = this.overhead.reduce((acc, cur) => {
        return acc + this.parseNumber(cur.value);
      }, 0);
      return overhead_total;
    },
    calcProfit(overhead_total, cost_total) {
      //lucro
      let overhead_cost_total = overhead_total + cost_total;

      this.profit.forEach((item) => {
        if (item.edited_base == "not") {
          item.cost_total = overhead_cost_total;
        }
      });

      //
      this.profit.forEach((item) => {
        if (item.edited_base == "not") {
          item.value = this.calcPercentage(
            item.percentage,
            overhead_cost_total
          );
        } else {
          item.value = this.calcPercentage(item.percentage, item.cost_total);
        }
      });

      //total de lucro
      let profitTotal = this.profit.reduce(
        (acc, cur) => acc + this.parseNumber(cur.value),
        0
      );

      return profitTotal;
    },
    calcTaxes(cost_total, overhead_total, profitTotal) {
      //impostos

      // soma todas as porcentagem de impostos
      let sum_tax_taxes = this.taxes.reduce(
        (acc, cur) => acc + this.numberToPercentage(cur.percentage),
        0
      );

      //calcula o valor base de impostos
      let sum_total =
        parseFloat(overhead_total + cost_total + profitTotal) /
        (1 - sum_tax_taxes);

      //define o valor base para cada linha (imposto)
      this.taxes.forEach((item) => {
        if (item.edited_base == "not") {
          //se não for uma coluna editada entra
          item.cost_total = sum_total;
        }
      });

      // calcula o valor de cada imposto
      this.taxes.forEach((item) => {
        if (item.edited_base == "not") {
          item.value = this.calcPercentage(item.percentage, sum_total);
        } else {
          item.value = this.calcPercentage(item.percentage, item.cost_total);
        }
      });

      //soma o valor de todos os impostos
      let taxes_total = this.taxes.reduce(
        (acc, cur) => acc + parseFloat(cur.value),
        0
      );

      //seta o valor total dos impostos
      //this.taxes[0].value = taxes_total;
      return taxes_total;
    },
    calcPercentage(percentage, total) {
      return parseFloat(parseFloat(percentage) / 100) * parseFloat(total);
    },
    numberToPercentage(number) {
      number = this.parseNumber(number);
      let result = number / 100;
      return result;
    },
    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
    add_cost(item) {
      
      if (this.costs.length != 0) {
        var lastCost = this.costs[this.costs.length - 1];
        var itemNext = parseFloat(lastCost.item) + 0.1;
      } else {
        itemNext = 1.1;
      }

      this.costs.push({
        item: itemNext,
        cost_id: item.id,
        cost: item,
        value: 0,
      });
      this.calcValueCost(this.costs); //calcula o valor total de cada custo
      this.saveData();
    },
    add_overhead() {
      if (this.overhead.length != 0) {
        var lastOverhead = this.overhead[this.overhead.length - 1];
        var itemNext = parseFloat(lastOverhead.item) + 0.1;
      } else {
        itemNext = 2.1;
      }
      this.overhead.push({
        item: itemNext,
        name: "NOVA DISPESA INDIRETA",
        cost_total: 0,
        percentage: 0,
        value: 0,
      });
      this.saveData();
    },
    add_profit() {
      if (this.profit.length != 0) {
        var lastProfit = this.profit[this.profit.length - 1];
        var itemNext = parseFloat(lastProfit.item) + 0.1;
        itemNext = itemNext.toFixed(1);
      } else {
        itemNext = 3.1;
      }

      this.profit.push({
        item: itemNext,
        name: "LUCRO",
        cost_total: "0",
        percentage: "0",
        value: "0",
      });
      this.saveData();
    },
    add_taxes() {
      if (this.taxes.length != 0) {
        var lastTaxes = this.taxes[this.taxes.length - 1];
        var itemNext = parseFloat(lastTaxes.item) + 0.1;
        itemNext = itemNext.toFixed(1);
      } else {
        itemNext = 4.1;
      }
      this.taxes.push({
        item: itemNext,
        name: "NOVO IMPOSTO",
        cost_total: 0,
        percentage: 0,
        value: 0,
      });
      this.saveData();
    },
    removeCost(item) {
      let index = this.costs.indexOf(item);
      this.costs.splice(index, 1);
      this.save();
    },
    removeOverhead(item) {
      let index = this.overhead.indexOf(item);
      this.overhead.splice(index, 1);
      this.save();
    },
    removeProfit(item) {
      let index = this.profit.indexOf(item);
      this.profit.splice(index, 1);
      this.save();
    },
    removeTaxes(item) {
      let index = this.taxes.indexOf(item);
      this.taxes.splice(index, 1);
      this.save();
    },
    saveData(){
      

      let formingPrice = {
        name:this.nameFormingPrice,
        costs: this.costs,
        overhead: this.overhead,
        profit: this.profit,
        taxes: this.taxes,
        total: this.priceTotal
      };

    this.btnSaveLoading = true;
    if (Object.keys(this.FormingPrice).length != 0){
      //update 
      service
        .update(formingPrice,this.FormingPrice.id)
        .then(() => {
         
          this.snackColor = 'success'
          this.snack = true
          this.snackText = "Dados salvo com sucesso."

        })
        .catch(() => {

          this.snackColor = 'success'
          this.snack = true
          this.snackText = "Erro ao salvar os dados."


        }).finally(()=>{
          this.btnSaveLoading = false;
        })

    }else{
      //store
  
      service
        .store(formingPrice)
        .then(() => {

          this.snackColor = 'success'
          this.snack = true
          this.snackText = "Dados salvo com sucesso."

        })
        .catch(() => {
          this.snackColor = 'success'
          this.snack = true
          this.snackText = "Erro ao salvar os dados."

        }).finally(()=>{
          this.btnSaveLoading = false;
        })


    }





      


    },
    
    formatMoney(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    padLock(item) {
      if (item.edited_base == "not") {
        item.edited_base = "yes";
      } else {
        item.edited_base = "not";
      }
      this.save();
    },
  },
};
</script>

<style scoped>
.title_table {
  width: 100%;
  height: 30px;
  background: #1976d2;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  padding-top: 3px;
}
.editable {
  color: #1976d2;
}
</style>